#dp-site {
    margin-top: 10%;
    margin-bottom: 8%;
    font-size: 80%;
    width: 100%;
}

#dp-site .card {
   background: none;
}

.data-protection {
    background-color: var(--whitish-color-02);
    border-radius: 2%;
    padding: 2%;
    text-wrap: wrap;
}

.data-protection a, .data-protection a:visited{
    color: var(--greyish-color-02);

}
.data-protection a:hover {
    color: var(--whitish-color-01);
}

.data-protection h1 {
    text-align: center;
    text-decoration: underline;
}
.data-protection h2:first-child {
    border-top: none !important;
}
.data-protection h2 {
    border-top: 0.01rem solid black;
}
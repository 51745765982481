.postsite-container {
    margin-top: 2%;
    display: flex;
    flex-direction: column-reverse;
}

#postsite_article {
    margin-top: 4%;
    margin-bottom: 4%;
}

.postsite-container .card {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--whitish-color-02);
    margin-top: 2%;
    font-size: larger;
}

.postsite-container .card-header {
    background: none !important;
    border: none !important;
    text-decoration: underline;
}

.postsite-container .card-title{
    font-size: 80% !important;
    text-align: center;
    border-bottom: 2% var(--transparent-black-01);
}

.card-body .container {
    border-top: 0.01rem solid var(--transparent-black-01);
    font-size: 70%;
    margin-bottom: 2%;
}


.postsite-container .card-img-top {
    width: 30%;
    height: 30%;
}
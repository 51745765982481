@font-face {
    font-family: "TT Interphases Mono";
    src: url("./fonts/TT\ Interphases\ Mono.ttf") format("truetype"),
}

@import url('https://fonts.googleapis.com/css?family=Major+Mono+Display');

* {
    --whitish-color-01: #ebe2e2;
    --whitish-color-02: #d7c4c4;
    --greyish-color-01: #212529;
    --greyish-color-02: #39353f;
    --blueish-color: #5e8ad4;
    --greenish-color: #8bc34a;
    --darkish-red-01: #EE151F;
    --darkish-red-02: #BF161E;
    --orangish-color-01: #e74c00;

    --transparent-black-01: rgba(0, 0, 0, 0.363);

    --font-var-01: 'TT Interphases Mono', monospace;
    --font-var-02: 'Major Mono Display', monospace;

    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: var(--font-var-01) !important;
    background-color: var(--greyish-color-02) !important;
    position: relative;
    padding-bottom: 3.625rem; /* 58px in rem */
    min-height: 100vh;
    font-size: 1.5rem !important;
    color: var(--greyish-color-02) !important;
}

header {
    text-align: center;
    color: var(--whitish-color-02);
}

header nav {
    padding-left: 2% !important;
    padding-right: 4% !important;
    padding-top: 1% !important;
}

.navbar-brand img {
    scale: 2;
}

.navbar-brand span {
    padding-left: 8%;
    font-size: 150%;
}



#navbar-nav {
    padding-left: 2%;
}

main {
    width: 90vw;
    margin: 0 auto;
    padding: 1.875rem 1.25rem; /* 30px 20px in rem */
}

article {
    padding: 1.25rem; /* 20px in rem */
    margin-bottom: 2.5rem; /* 4rem in rem */
    border-radius: 0.3125rem; /* 5px in rem */
}

#post-list {
    margin-top: 5%;
}

#post-list .single-post {
    box-shadow: 0.01rem 1rem 1rem var(--transparent-black-01);
    border-radius: 2%;

    margin-top: 2%;
    padding: 2%;
    background-color: var(--whitish-color-01);
}

article:last-child {
    margin-bottom: 0;
}

article h3 {
    margin: 0.625rem 0 1.5625rem 0; /* 10px 0 25px 0 in rem */
}

article p {
    margin-top: 1rem; /* 16px in rem */
    line-height: 1.5rem; /* 24px in rem */
}

footer {
    text-align: center;
    background-color: var(--greyish-color-01);
    color: var(--whitish-color-02);
    padding: 1.25rem; /* 20px in rem */
    position: absolute;
    bottom: 0;
    width: 100%;
}



@media (max-width: 376px) {
    .navbar-brand {
        padding-top: 4% !important;
    }
    .navbar-brand img {
        width: 8%;
        height: 8%;
    }
    .navbar-brand span {
        text-wrap: wrap;
        
    }
    main {
        margin-top: 15%;
    }
    .imprint-site {
        margin-top: 20% !important;
    }
}


@media (max-width: 48rem) {
    header {
        padding: 1.875rem 1.25rem; /* 30px 20px in rem */
    }

    header h1 {
        font-size: 2.25rem; /* 36px in rem */
        margin-bottom: 1.375rem; /* 22px in rem */
    }

    header p {
        font-size: 1.125rem; /* 18px in rem */
    }

    main {
        width: 100vw;
        padding: 1.25rem; /* 20px in rem */
    }

    article {
        margin-bottom: 1rem; /* 16px in rem */
        font-size: 0.875rem; /* 14px in rem */
    }

    article h3 {
        margin: 0.625rem 0 1.25rem 0; /* 10px 0 20px 0 in rem */
    }

    article p {
        margin-top: 1rem; /* 16px in rem */
        line-height: 1.25rem; /* 20px in rem */
    }
}
